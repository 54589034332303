<template>
  <div class="pa-4" style="max-width: 600px">
    <AssignmentRuleMatchConfidenceScore @saved="$emit('saved')" />
  </div>
</template>

<script>
import AssignmentRuleMatchConfidenceScore from './AssignmentRuleMatchConfidenceScore'

export default {
  components: {
    AssignmentRuleMatchConfidenceScore,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
}
</script>
