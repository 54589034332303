<template>
  <ApLoading v-if="isLoading" class="mt-10" text="Loading ..." />

  <RulesStatusError v-else-if="isError" @click="getRulesAndMetadata" />

  <div v-else>
    <div class="mx-auto">
      <div class="text-h5 font-weight-black mb-6">
        Match Confidence Scores Assignment
      </div>
    </div>

    <RulesStatus
      v-if="assignments.length === 0"
      class="my-10"
      status-icon="$mdi-database-off-outline"
      title="No Match Criterias defined yet"
      description="Please go back to the Matching tab to define them"
      button-icon="$mdi-arrow-left"
      button-label="Go back"
    />

    <template v-else>
      <v-form ref="form">
        <div v-for="assignment in assignments" :key="assignment.prop">
          <template v-if="assignment.criterias.length > 0">
            <MatchingCriteriaConfidenceScoreTitle
              :title="assignment.name"
              :color="assignment.color"
              :icon="assignment.icon"
              :total="assignment.criterias.length"
            />

            <DropArea>
              <MatchingCriteriaConfidenceScoreCriteria
                v-for="(criteria, index) in assignment.criterias"
                :key="criteria.index"
                :conditions="criteria.conditions"
                :class="{
                  'mb-1': index < assignment.criterias.length - 1,
                }"
              />
            </DropArea>

            <v-row class="mt-0">
              <v-col cols="12" md="6">
                <div class="mb-1">Assign to:</div>

                <v-autocomplete
                  v-model="assignment.assignTo"
                  :items="users"
                  :rules="assignToRules"
                  outlined
                  dense
                  placeholder="Choose a user"
                  item-text="username"
                  return-object
                >
                  <template #item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.username }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="font-weight-regular">
                        {{ data.item.roleName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-form>
    </template>
  </div>
</template>

<script>
import DropArea from './DropArea.vue'
import MatchingCriteriaConfidenceScoreTitle from './MatchingCriteriaConfidenceScoreTitle'
import MatchingCriteriaConfidenceScoreCriteria from './MatchingCriteriaConfidenceScoreCriteria'
import ApLoading from '@/components/common/ApLoading'
import RulesStatus from './RulesStatus'
import RulesStatusError from './RulesStatusError'
import { RULE_TYPES } from '@/api/business-rules/rules'
import { mapState, mapActions } from 'vuex'
import { isEqual, cloneDeep } from 'lodash-es'

const mcsConfigs = {
  high: {
    matchConfidenceScoreCode: 'high',
    name: 'High Score',
    icon: '$mdi-alpha-hcircle',
    color: 'ap-green',
    minConfidence: 1,
  },
  medium: {
    matchConfidenceScoreCode: 'medium',
    name: 'Medium Score',
    icon: '$mdi-alpha-mcircle',
    color: 'ap-yellow',
    minConfidence: 0.7,
  },
  low: {
    matchConfidenceScoreCode: 'low',
    name: 'Low Score',
    icon: '$mdi-alpha-lcircle',
    color: 'ap-orange',
    minConfidence: 0,
  },
}

export default {
  components: {
    DropArea,
    MatchingCriteriaConfidenceScoreTitle,
    MatchingCriteriaConfidenceScoreCriteria,
    ApLoading,
    RulesStatus,
    RulesStatusError,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      assignments: [],
      originalAssignments: [],
      assignToRules: [(v) => !!v || 'Assign To is required'],
    }
  },
  computed: {
    ...mapState('business-rules', {
      directorRule: (state) => state.directorRule,
      matchCriteriaRule: (state) => state.directorRule.rules.MatchCriteria,
      isTriggeringSave: (state) =>
        state.directorRule.rules?.Assignment?.isTriggeringSave,
      rulesMetadata: (state) => state.rulesMetadata,
      multiMapRule: (state) => state.directorRule.rules.MultiMap,
      assignmentRule: (state) => state.directorRule.rules.Assignment,
    }),
    users() {
      return this.rulesMetadata?.Assignment?.[this.multiMapRule.primaryEntity]
        .users
    },
    isMatchCriteriaRuleSaved() {
      return (
        !this.matchCriteriaRule ||
        Object.keys(this.matchCriteriaRule).length < 2
      )
    },
  },
  watch: {
    async isTriggeringSave(value) {
      if (value) {
        try {
          if (this.$refs.form.validate()) {
            if (!isEqual(this.originalAssignments, this.assignments)) {
              if (this.assignmentRule?.id) {
                await this.updateAssignmentRule({
                  rule: this.assignmentRule,
                  assignments: this.assignments,
                })
              } else {
                await this.createAssignmentRule({
                  directorRuleId: this.$route.params.directorRuleId,
                  rule: this.assignmentRule,
                  assignments: this.assignments,
                })
              }
            }

            this.$emit('saved')
          } else {
            this.resetTriggerSaveCurrentRule(RULE_TYPES.assignment)
          }
        } finally {
          this.resetTriggerSaveCurrentRule(RULE_TYPES.assignment)
        }
      }
    },
  },
  async created() {
    this.getRulesAndMetadata()
  },
  methods: {
    ...mapActions('business-rules', [
      'getRule',
      'getRuleEntityMetadata',
      'resetTriggerSaveCurrentRule',
      'updateAssignmentRule',
      'createAssignmentRule',
      'assignLocalRuleToDirector',
    ]),
    async getRulesAndMetadata() {
      this.isLoading = true
      this.isError = false

      try {
        const requests = []

        // Get Match Criterias
        if (!this.isMatchCriteriaRuleSaved) {
          if (this.directorRule.rules?.MatchCriteria?.id) {
            requests.push(
              this.getRule(this.directorRule.rules.MatchCriteria.id)
            )
          }
        }

        // Get Assignments
        if (this.directorRule.rules?.Assignment?.id) {
          requests.push(this.getRule(this.directorRule.rules.Assignment.id))
        }

        // Get Metadata
        if (
          !this.rulesMetadata?.Assignment?.[this.multiMapRule.primaryEntity]
        ) {
          requests.push(this.getMetadata())
        }

        await Promise.all(requests)

        this.generateAssignments()

        if (!this.assignmentRule?.id) {
          const payload = {
            id: null,
            name: `${this.directorRule.name}: Assignment Rule`,
            description: 'Manually created Assignment rule',
            entities: 'Account',
            type: RULE_TYPES.assignment,
            rule: null,
          }

          this.assignLocalRuleToDirector({ rule: payload })
        }
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    async getMetadata() {
      await this.getRuleEntityMetadata({
        entities: [this.multiMapRule.primaryEntity],
        ruleType: RULE_TYPES.assignment,
      })
    },
    generateAssignments() {
      let matchingCriterias = this.matchCriteriaRule.rule.config.map(
        (item) => item.config
      )
      // Mix Match Criterias, Assignments and metadata to present the form
      this.assignments = Object.keys(mcsConfigs)
        .map((value) => {
          let savedAssignment = null

          if (this.assignmentRule?.id) {
            savedAssignment = this.assignmentRule.assignments.find(
              (assignment) => assignment.mcs === value
            )
          }

          const criteriasInRange = matchingCriterias.filter(
            (el) => el.confidence >= mcsConfigs[value].minConfidence
          )

          matchingCriterias = matchingCriterias.filter(
            (el) => !(el.confidence >= mcsConfigs[value].minConfidence)
          )

          return {
            ...mcsConfigs[value],
            criterias: criteriasInRange,
            assignTo: savedAssignment
              ? this.users.find((user) => user.id === savedAssignment.userId)
              : null,
          }
        })
        .filter((el) => el.criterias.length > 0)

      this.originalAssignments = cloneDeep(this.assignments)
    },
  },
}
</script>
